


const About =()=>{

    return(
        <div style={{margin:'24px'}}>
            <h2 style={{textAlign:'center', fontSize:'1.75rem'}}>About Us</h2>
        <p>My name is Anthony. I created Dog Shuffle using React for the web pages, Express for the web server, 
            and PostgreSQL for the database. 
            Follow me on <a rel="noreferrer" href='https://www.youtube.com/@speedrunyourknowledge' target='_blank'>
            YouTube</a> to see fun educational videos.
             </p>

        <p>All the dog pictures are retrieved from <a rel="noreferrer" 
        href='https://dog.ceo/dog-api/' target="_blank">Dog API.</a> Dog API collected 
        20,000 of their photos from the <a rel="noreferrer" 
        href='http://vision.stanford.edu/aditya86/ImageNetDogs/' target="_blank">Stanford Dogs dataset.</a> The purpose of the 
        Stanford Dogs dataset was to train an AI to identify dog breeds. You can submit photos of 
        your own dog to <a rel="noreferrer" 
        href='https://dog.ceo/dog-api/' target="_blank">Dog API.</a> Your photos may even appear in Dog Shuffle!
        </p>
        </div>
    )
}

export default About